.sideNav {
    overflow: 'hidden';
    height: 100vh;
}

.sideNavTop {
    min-height: calc(100vh - 50px);
    max-height: calc(100% - 50px);
    overflow: auto;
}

.sideNavBottom {
    min-height: 50px;
}