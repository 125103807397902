body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}



/* Fix issue with vertical menu mode */
.ant-menu-vertical .ant-menu-title-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.ant-collapse-small > .ant-collapse-item > .ant-collapse-header {
    padding: 4px 12px;
}



table {
    background: rgb(40, 44, 52);
    border-radius: 8px;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

th {
    border-bottom: 1px solid #364043;
    font-weight: normal;
    padding: 4px 12px;
    text-align: left;
    margin: 0;
    background: rgba(0, 0, 0, .25);
}

td {
    padding: 2px 12px;
}

tr:first-child td {
    padding-top: 4px;
}

tr:last-child td {
    padding-bottom: 4px;
}

tbody tr {
    transition: background 0.25s ease;
}

tbody tr:hover {
    background: rgba(0, 0, 0, .25);
}



::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .2);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, .4);
}