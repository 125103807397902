.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.messages {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    height: 100%;
    max-width: 800px;
    width: 100%;
    padding: 30px 90px 0 0;
}

.messagesInner {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    gap: 32px;
    padding-top: 10px;
}

.message {
    display: flex;
    flex-direction: column;
}

.messageHeader {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
}

.messageHeaderItems {
    display: flex;
    align-items: center;
    gap: 8px;
}

.messageContainer {
    display: flex;
    margin-left: 40px;
}

.messageContent {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    gap: 8px;
}

.messageContent :global(p) {
    margin: 0;
}

.messagePartTool {
    display: inline-flex;
    flex-direction: column;
    max-width: 100%;
}

.messagePartMarkdown {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.editMessage {
    display: flex;
    width: 100%;
    align-items: center;
}

.editMessage :global(textarea) {
    font-size: 14px;
    padding: 0;
    resize: 'none';
    min-height: 22px;
}

.collapseContent {
    display: flex;
    flex-direction: column;
    margin: -12px;
    position: relative;
}

.toolCallLabel {
    display: flex;
    align-items: center;
    gap: 8px;
}

.toolCallErrorButton {
    display: flex;
    height: 14px !important;
    padding: 0 !important;
    border: 0;
}

.toolResult {
    margin: 0;
}

.bottomArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    max-width: 800px;
    width: 100%;
}

.userInputArea {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 16px;
}

.images {
    display: flex;
    gap: 12px;
    width: calc(100% - 2px);
    background: #141414;
    padding: 16px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    left: 1px;
    top: 1px;
}

.chatImage {
    max-width: 100%;
    max-height: 100px;
    border-radius: 4px;
}

.image {
    display: flex;
    flex-direction: column;
    position: relative;
}

.imageRemoveButton {
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1;
}

.imageButton {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;
}

.textAreaContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.textArea {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.actionButtons {
    margin-bottom: 4px;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding-top: 16px;
    max-width: 800px;
}