.settingsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
}

.settingsForm {
    width: 100%;
    max-width: 800px;
}