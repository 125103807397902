.agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    transition: padding-right 300ms ease;
    width: 100%;
}

.agent.withToolAssistant {
    padding-right: 600px;
}

.agentInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
}

.agentForm {
    width: 100%;
}

.editor {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;
    flex-grow: 2;
    border: solid 1px #424242;
    border-radius: 4px;
    overflow: hidden;
    transition: border-color 250ms ease;
    position: relative;
}

.editor:hover {
    border-color: #3c89e8;
}

.editor:focus-within {
    border-color: #3c89e8;
}

.editor.editorError {
    border-color: #ff0000;
}

.editor.editorError:hover {
    border-color: #ff0000;
}

.editor.editorError:focus-within {
    border-color: #ff0000;
}

.editorErrorButton {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding-top: 16px;
    max-width: 800px;
}

.imagePickerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    overflow: hidden;
    border: dashed 2px #424242;
    transition: border-color 250ms ease;
}

.imagePickerButton:hover {
    border-color: #3c89e8;
}

.imagePickerButton:focus {
    border-color: #3c89e8;
}

.imagePickerButton:has(img) {
    border-color: transparent;
}